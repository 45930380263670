import React, {Component, Fragment} from 'react'
import Modal from 'react-modal'
import styled from '@emotion/styled'
import vars from '../styles/vars'

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 1200,
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        border: 'none',
        background: 'none',
        padding: 0,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
    },
}

const ModalBody = styled('div')`
    position: absolute;
    background: ${vars.white};
    padding: 6rem 3rem 2rem;
    min-width: 80%;
    top: 10vh;

    @media screen and (min-width: ${vars.bpLargerThanDesktop}) {
        padding: 6rem;
        min-width: auto;
        width: 60rem;
    }
`

const ModalClose = styled('button')`
    position: absolute;
    top: 0;
    right: 0;
    background: ${vars.black};
    border: none;
    border-radius: 0;
    font-size: 2rem;
    height: 6rem;
    padding: 0 2rem;
    color: ${vars.white};

    @media screen and (min-width: ${vars.bpLargerThanTablet}) {
        font-size: 3rem;
    }
`

class ModalContainer extends Component {
    state = {
        isOpen: false,
    }

    openModal = label => {
        this.setState({isOpen: true})
        // fire click event for Google Analytics
        if (typeof window !== 'undefined' && typeof window.ga !== 'undefined') {
            window.ga('send', 'event', {
                eventCategory: 'UI',
                eventAction: 'click',
                eventLabel: label,
            })
        }
    }

    closeModal = () => {
        this.setState({isOpen: false})
    }
    render() {
        const {isOpen} = this.state
        const {openComponent, children} = this.props
        return (
            <Fragment>
                {openComponent(this.openModal)}
                <Modal
                    isOpen={isOpen}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                    contentLabel="Modal"
                    style={modalStyles}
                >
                    <ModalBody>
                        <ModalClose
                            className="modal-close"
                            onClick={this.closeModal}
                        >
                            &times;
                        </ModalClose>
                        {children}
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

export default ModalContainer
