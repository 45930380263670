/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {graphql, Link} from 'gatsby'
import styled from '@emotion/styled'
import {OutboundLink} from 'gatsby-plugin-google-analytics'
import vars from '../styles/vars'
import {Container, Page, Intro, button} from '../styles'
import Layout from '../components/Layout'
import DividerPanda from '../components/DividerPanda'
import DividerWrench from '../components/DividerWrench'
import EmailSubscribe from '../components/EmailForm'
import ModalContainer from '../components/ModalContainer'

const Row = styled('div')`
    width: 90%;
    margin: auto;
    flex-direction: column;
    display: flex;
    color: ${vars.white};

    @media (min-width: ${vars.bpLargerThanTablet}) {
        width: 80%;
        flex-direction: row;
    }
`

const Box = styled('div')`
    background-color: ${vars.black};
    border-radius: 5px;
    padding: 4rem;

    & + div {
        margin-top: 2rem;
    }

    @media (min-width: ${vars.bpLargerThanTablet}) {
        flex: 1;

        & + div {
            margin-top: 0;
            margin-left: 2rem;
        }
    }
`

const detail = css`
    font-style: normal;
    font-size: 2rem;
    margin: 0;
`

const IndexPage = ({data}) => {
    const {title, intro, buttonLabel} = data.markdownRemark.frontmatter
    return (
        <Layout>
            <Page>
                <Container>
                    <Intro>
                        <h1>{title}</h1>
                        <p>{intro}</p>
                    </Intro>
                    <ModalContainer
                        openComponent={handleOpen => (
                            <button
                                css={button}
                                onClick={() => {
                                    handleOpen('Connect with us button')
                                }}
                            >
                                {buttonLabel}
                            </button>
                        )}
                    >
                        <div className="lede">
                            <h2>Stay in the loop</h2>
                            <p>
                                Want updates and information about upcoming
                                events delivered straight to your inbox? Thought
                                so.
                            </p>
                        </div>
                        <EmailSubscribe />
                    </ModalContainer>
                </Container>

                <DividerPanda />

                <Row>
                    <Box>
                        <h5>Location</h5>
                        <address css={detail}>
                            {'110 North Loop Blvd E'}
                            <br />
                            {'Austin, TX 78751'}
                        </address>
                        <OutboundLink
                            css={[button, {marginTop: '2rem'}]}
                            href="https://www.google.com/maps/place/Monkeywrench+Books/@30.3179561,-97.7238782,17z/data=!4m5!3m4!1s0x8644ca6b9e664cf1:0x905442d73f599b89!8m2!3d30.3179515!4d-97.7216895"
                            target="_blank"
                        >
                            {'Get directions'}
                        </OutboundLink>
                    </Box>
                    <Box>
                        <h5>{'Hours*'}</h5>
                        <p css={detail}>{'M-Sun 12–8pm'}</p>
                        <small
                            css={css`
                                line-height: 1rem;
                            `}
                        >
                            *Hours may vary. If you'd like to see us open more
                            often, consider{' '}
                            <Link
                                to="/volunteer"
                                css={css`
                                    color: ${vars.linkColor};
                                    &:active,
                                    &:focus,
                                    &:visited {
                                        color: ${vars.linkColor};
                                    }
                                `}
                            >
                                becoming a volunteer
                            </Link>
                        </small>
                    </Box>
                </Row>

                <DividerWrench />
            </Page>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query {
        markdownRemark(frontmatter: {id: {eq: "home"}}) {
            frontmatter {
                title
                intro
                buttonLabel
            }
        }
    }
`
