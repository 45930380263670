/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Component} from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import {FormGroup, formControl, button} from './../styles'

// Can't figure out how to refactor without this.
/* eslint-disable react/jsx-no-bind */

const CustomForm = ({status, message, onValidated}) => {
    let email
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const submit = () =>
        email &&
        regex.test(email.value) &&
        onValidated({
            EMAIL: email.value,
        })

    return (
        <FormGroup>
            {status === 'sending' && <div>{'sending...'}</div>}
            {status === 'error' && (
                <p dangerouslySetInnerHTML={{__html: message}} />
            )}
            {status === 'success' && (
                <p dangerouslySetInnerHTML={{__html: message}} />
            )}
            <label>{'Your email'}</label>
            <input
                css={formControl}
                ref={node => (email = node)}
                type="email"
                placeholder="destroy@every.thing"
            />
            <br />
            <button css={button} onClick={submit}>
                Submit
            </button>
        </FormGroup>
    )
}

class SubscribeForm extends Component {
    render() {
        const url =
            '//monkeywrenchbooks.us7.list-manage.com/subscribe/post?u=6cd8e997251fc0b0a88b19ea2&amp;id=f7316a459e'

        return (
            <div>
                <MailchimpSubscribe
                    url={url}
                    render={({subscribe, status, message}) => (
                        <CustomForm
                            status={status}
                            message={message}
                            onValidated={formData => subscribe(formData)}
                        />
                    )}
                />
            </div>
        )
    }
}

export default SubscribeForm
