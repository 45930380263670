/** @jsx jsx */
import {jsx} from '@emotion/core'
import {css} from '@emotion/core'
import vars from '../styles/vars'
import wrench from '../images/wrench.svg'

const DividerWrench = () => (
    <div
        css={css`
            position: relative;
            border-bottom: 1px solid ${vars.black};
            margin: 12rem 0;
        `}
    >
        <div
            css={css`
                position: absolute;
                bottom: -10.9rem;
                left: 50%;
                transform: translateX(-50%);

                &::before {
                    content: '';
                    position: absolute;
                    background-color: ${vars.white};
                    left: -7rem;
                    right: -6rem;
                    top: 0;
                    bottom: 0;
                }
            `}
        >
            <img
                css={css`
                    transform: rotate(-45deg) scale(-1);
                    width: 20rem;
                    z-index: 1;
                `}
                src={wrench}
                alt=""
            />
        </div>
    </div>
)

export default DividerWrench
